<template>
  <div>
    <!-- <b-card title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card> -->

    <!-- <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card> -->

    <b-card title="System Landscape Arigini Pratama Putra">
      <b-card-text>Welcome <b> {{ data }} </b> </b-card-text>
    </b-card>

    <b-row class="match-height">
      <b-col md="6">
        <EcommerceGoalOverview :data="diagram" />
      </b-col>
      <b-col md="6">
        <analytics-support-tracker :data="dataDispo" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import {
  BCard, BCardText, BLink, BCol, BRow,
} from 'bootstrap-vue'
import store from '@/store/index'
import axios from '@axios'
import EcommerceGoalOverview from './components/EcommerceGoalOverview.vue'
import AnalyticsSupportTracker from './components/AnalyticsSupportTracker.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCol,
    BRow,
    EcommerceGoalOverview,
    AnalyticsSupportTracker,
  },

  data() {
    return {
      data: {},
      diagram: {
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },

      dataDispo: {
        title: 'Disposisi',
        labels: ['Completed Disposisi'],
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        totalTicket: 163,
        newTicket: 29,
        openTicket: 63,
        responseTime: 1,
        supportTrackerRadialBar: {
          series: [83],
        },
      },
    }
  },

  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.data = userData.user.nama
    // this.getSummaryStatus()
  },

  methods: {
    async getSummaryStatus() {
      const { data } = await axios.get('/summary/status')
      const total = data.DONE + data.LATE + data.PROCESS
      const percentage = (data.DONE / total) * 100

      this.dataDispo = {
        title: 'Disposisi',
        labels: ['Completed Disposisi'],
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        totalTicket: total,
        newTicket: data.DONE,
        openTicket: data.PROCESS,
        responseTime: data.LATE,
        supportTrackerRadialBar: {
          series: total === 0 ? 0 : percentage.toFixed(2),
        },
      }
    },
  },
}
</script>

<style>
</style>
