<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title> Work Summary </b-card-title>
      <!-- <b-dropdown
        no-caret
        right
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in data.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>
    <!--/ title and dropdown -->
    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ floof.totalTicket }}
          </h1>
          <small>Total</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >
          <!-- apex chart -->
          <vue-apex-charts
            id="chart"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="floof.supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between mt-3">
        <div class="text-center">
          <b-card-text class="mb-50">
            Done
          </b-card-text>
          <span class="font-large-1 font-weight-bold"> {{ floof.newTicket }} </span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            On Process
          </b-card-text>
          <span class="font-large-1 font-weight-bold"> {{ floof.openTicket }} </span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Late
          </b-card-text>
          <span class="font-large-1 font-weight-bold"> {{ floof.responseTime }} </span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  // BDropdown,
  // BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from '@axios'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    // BDropdown,
    // BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  // props: {
  //   data: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      floof: {},
      chart: null,
      data: {
        title: 'Disposisi',
        labels: ['Completed Disposisi'],
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        totalTicket: 163,
        newTicket: 29,
        openTicket: 63,
        responseTime: 1,
        supportTrackerRadialBar: {
          series: [83],
        },
      },
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Work done'],
        },
      },
    }
  },

  created() {
    this.getSummaryStatus()
  },

  methods: {
    async getSummaryStatus() {
      const { data } = await axios.get('/summary/status')
      const done = data.DONE ? data.DONE : 0
      const late = data.LATE ? data.LATE : 0
      const process = data.PROCESS ? data.PROCESS : 0
      const total = done + late + process
      const percentage = (done / total) * 100

      // this.floof = 12
      this.floof = {
        title: 'Disposisi',
        labels: ['Completed Disposisi'],
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        totalTicket: total,
        newTicket: done,
        openTicket: process,
        responseTime: late,
        supportTrackerRadialBar: {
          series: total === 0 ? 0 : [percentage.toFixed(1).toString()],
        },
      }
    },
  },
}
</script>
